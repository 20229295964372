/*.navbar-logo {
  padding: 0px 40px 0px 40px;
  inline-size: 15%;
  margin-top: 10px;
}*/

.ui.container.color-creme {
  background-color: #eceae5;
}

#header-container {
  position: relative;
  background-color: #eceae5;
  z-index: 100;
}

.ui.secondary.menu {
  margin: 0px 0px -7px 0px;
}

.color-yellow {
  border-top: 10px solid #f8b510;
  border-bottom: 0px;
}

.flex {
  display: flex;
  gap: var(--gap, 2rem);
}

/* primary header */
/*.navbar-logo {
  margin: 0.5rem -1rem;
  margin-top: 10px;
}*/

#header {
  align-items: center;
  justify-content: space-between;
}

.mobile-nav-toggle{
    display: none;
}

.primary-navigation {
  list-style: none;
  padding: 1rem 2rem;
  margin: 0;
}

.primary-navigation a {
  text-decoration: none;
  color: black;
  font-size: medium;
}

.primary-navigation a > span {
  font-weight: 700;
  margin-inline-end: 0.5em;
}

@media (max-width: 42em) {
  .primary-navigation {
    --gap: 3em;
    position: fixed;
    inset: 0 0 0 30%;
    flex-direction: column;
    padding: min(30vh, 10rem) 5em;
    background: hsl(0 0% 0% / 0.9);
    transform: translateX(100%);
    transition: transform 350ms ease-out;
  }

  .primary-navigation.data-visible {
    transform: translateX(0%);
  }

  .primary-navigation a {
    color: white;
  }

  @supports (backdrop-filter: blur(0.7rem)) {
    .primary-navigation {
      background: hsl(0 0% 0% / 0.7);
      backdrop-filter: blur(0.7rem);
    }
  }

  .mobile-nav-toggle{
      display: block;
      position: absolute;
      z-index: 200;
      background: url("../../public/icon-hamburger.svg");
      background-repeat: no-repeat;
      width: 2rem;
      border:0;
      aspect-ratio: 1;
      top: 2rem;
      right: 2rem;
  }

  .mobile-nav-toggle[aria-expanded=true] {
      background-image: url("../../public/icon-close.svg");
  }
  
}
