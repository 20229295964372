/** BEGIN: Non Openmrs CSS **/
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

form {
  margin-left: auto;
  margin-right: auto;
}

#form-container {
  display: flex;
  justify-content: center;
}

h4.title {
  text-align: center;
  margin-bottom: 45px;
}
:root {
  --omrs-color-ink-lowest-contrast: rgba(47, 60, 85, 0.18);
  --omrs-color-ink-low-contrast: rgba(60, 60, 67, 0.3);
  --omrs-color-ink-medium-contrast: rgba(19, 19, 21, 0.6);
  --omrs-color-interaction: #1e4bd1;
  --omrs-color-interaction-minus-two: rgba(73, 133, 224, 0.12);
  --omrs-color-danger: #b50706;
  --omrs-color-bg-low-contrast: #eff1f2;
  --omrs-color-ink-high-contrast: #121212;
  --omrs-color-bg-high-contrast: #ffffff;
}
/** END: Non Openmrs CSS **/
div.omrs-input-group {
  margin-bottom: 1.5rem;
  position: relative;
  width: 20.4375rem;
}

/* Input*/
.omrs-input-underlined > input,
.omrs-input-filled > input {
  border: none;
  background-color: #eceae5;
  border-bottom: 0.125rem solid var(--omrs-color-ink-medium-contrast);
  width: 100%;
  height: 2.7rem;
  font-size: 1.2rem;
  padding-left: 0.875rem;
  line-height: 147.6%;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.omrs-input-underlined > input:focus,
.omrs-input-filled > input:focus {
  outline: none;
}

.omrs-input-underlined > .omrs-input-label,
.omrs-input-filled > .omrs-input-label {
  position: absolute;
  top: 0.9375rem;
  left: 0.875rem;
  line-height: 147.6%;
  color: var(--omrs-color-ink-medium-contrast);
  transition: top 0.2s;
}

.omrs-input-underlined > svg,
.omrs-input-filled > svg {
  position: absolute;
  top: 0.9375rem;
  right: 0.875rem;
  fill: var(--omrs-color-ink-medium-contrast);
}

.omrs-input-underlined > .omrs-input-helper,
.omrs-input-filled > .omrs-input-helper {
  font-size: 0.9375rem;
  color: var(--omrs-color-ink-medium-contrast);
  letter-spacing: 0.0275rem;
  margin: 0.125rem 0.875rem;
}

.omrs-input-underlined > input:hover,
.omrs-input-filled > input:hover {
  background: #d1d3cd;
  border-color: var(--omrs-color-ink-high-contrast);
}

.omrs-input-underlined > input:focus + .omrs-input-label,
.omrs-input-underlined > input:valid + .omrs-input-label,
.omrs-input-filled > input:focus + .omrs-input-label,
.omrs-input-filled > input:valid + .omrs-input-label {
  top: 0.15rem;
  font-size: 0.8rem;
  margin-bottom: 32px;
}

.omrs-input-underlined:not(.omrs-input-danger)
  > input:focus
  + .omrs-input-label,
.omrs-input-filled:not(.omrs-input-danger) > input:focus + .omrs-input-label {
  color: var(--omrs-color-interaction);
}

.omrs-input-underlined:not(.omrs-input-danger) > input:focus,
.omrs-input-filled:not(.omrs-input-danger) > input:focus {
  border-color: var(--omrs-color-interaction);
}

.omrs-input-underlined:not(.omrs-input-danger) > input:focus ~ svg,
.omrs-input-filled:not(.omrs-input-danger) > input:focus ~ svg {
  fill: var(--omrs-color-ink-high-contrast);
}

#submit-form-btn {
  background-color: #1d1d1b;
  border: 1px solid #eceae5;
  line-height: 0;
  font-size: 17px;
  box-sizing: border-box;
  padding: 20px 15px;
  border-radius: 60px;
  color: #eceae5;
  font-weight: 100;
  letter-spacing: 0.01em;
  z-index: 1;
  display: flex;
  margin: 0 auto;
}

#submit-form-btn:hover {
  color: #1d1d1b;
  background-color: #eceae5;
  transition: 0.3s 0.1s;
}

#submit-form-btn:focus {
  color: #1d1d1b;
  background-color: #eceae5;
}
