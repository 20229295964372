* {
  font-family: "ButlerRegular", serif;
  font-weight: bold;
  font-style: normal;
}

.ui.header {
  font-family: "ButlerRegular", serif;
  font-weight: bold;
  font-style: normal;
}

hr.thin {
  border: 0px solid;
}

.column.vert-align {
  display: flex;
  align-items: center;
}

#home-container {
  /*position: fixed;
  z-index: 50;*/
}

#home-segment {
  background-color: #eceae5;
  padding-bottom: 50px;
  padding-top: 50px;
}

#product-segment {
  background-color: #1d1d1b;
  padding-bottom: 50px;
  padding-top: 30px;
}

#main-container {
  margin-bottom: 20px;
}

#about-segment {
  margin-top: 30px;
}

#galeria-segment {
  background-color: #10476a;
  margin-top: 50px;
}

#signup-segment {
  background-color: #1d1d1b;
  margin-top: 50px;
  padding: 50px 0;
}

#product-img {
  inline-size: 80%;
  aspect-ratio: 7 / 8;
  object-fit: cover;
}

.ui.header {
  margin-bottom: 10px;
  margin-top: 0px;
}

.ui.header.title {
  margin-bottom: 15px;
  margin-top: 10px;
  margin-left: 30px;
}

.creme-logo-img {
  position: absolute;
  top: 50%;
  transform: translate(0.5%, -50%);
}

.ui.header.creme-text {
  color: #eceae5;
}

.ui.header.creme-text.title {
  color: #eceae5;
  margin-bottom: 40px;
  margin-top: 20px;
}

.creme-text {
  color: #eceae5;
}

.orange-text {
  color: #f8b510;
}

.black-text {
  color: #1d1d1b;
}

.ui.header.red-text {
  color: #de4f24;
}

.ui.header.black-title {
  color: #1d1d1b;
}

.ui.header.white-title {
  color: #eceae5;
}

.ui.header.orange-title {
  color: #f8b510;
}

.ui.header.red-title {
  color: #de4f24;
}

.text-header {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

@media (max-width: 990px) and (min-width: 760px) {
  #main-img {
    width: 300px;
    height: 224px;
  }
}