@import "https://unpkg.com/open-props";

.media-scroller {
  --_spacer: var(--size-3);
  display: grid;
  gap: var(--_spacer);
  grid-auto-flow: column;
  grid-auto-columns: 21%;

  padding: 0 var(--_spacer) var(--_spacer);

  overflow-x: auto;
  overscroll-behavior-inline: contain;
}

.media-scroller--with-groups {
  grid-auto-columns: 25%;
}

.media-group {
  display: grid;
  gap: var(--_spacer);
  grid-auto-flow: column;
}

.media-element {
  display: grid;
  grid-template-rows: min-content;
  gap: var(--_spacer);
  padding: var(--_spacer);
  background: #1d1d1b;
  border-radius: var(--radius-2);
  box-shadow: var(--shadow-2);
}

.media-element > img {
  inline-size: 100%;
  aspect-ratio: 6 / 8;
  object-fit: cover;
}

.snaps-inline {
  scroll-snap-type: inline mandatory;
  scroll-padding-inline: var(--_spacer, 1rem);
}

.snaps-inline > * {
  scroll-snap-align: start;
}

@media only screen and (max-width: 600px) and (min-width: 500px){
  .media-scroller--with-groups {
    grid-auto-columns: 50%;
  }

  .media-element {
    padding: 0.5rem;
  }
}

@media only screen and (max-width: 500px){
  .media-scroller--with-groups {
    grid-auto-columns: 80%;
  }

  .media-element {
    padding: 0.5rem;
  }
}