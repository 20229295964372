#about-city-text {
  margin-top: 30px;
  color: #1d1d1b;
}

#about-city-text > hr {
  margin-top: 20px;
  margin-bottom: 20px;
}

#about-city-text > a {
  color: #eceae5;
  font-size: medium;
}

#about-city-text > a:hover {
  color: #1b5557;
}

#about-city-text > h4 {
  align-items: center;
}

.ui.header{
  line-height: 25px;
}
